<template>
    <div>
        <header-app />
        <div>
            <router-view />
        </div>
        <footer-app />
    </div>
</template>

<script>
    import Footer from '../../containers/footer'
    import Header from '../../containers/header'

    export default {
        components: {
            'footer-app': Footer,
            'header-app': Header
        }
    }
</script>

<style>
    .card-header {
        background-color: orange !important;
    }
</style>