<template>
    <footer class="page-footer">
        <div class="footer-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 pr-5">
                        <p class="mb-0 text-muted">
                            Todos os direitos reservados - 2022
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Vue from 'vue';

    export default {
        data() {
            return {};
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
        },
        watch: {
            // call again the method if the route changes
        },
        methods: {
            logout: () => {
                //call the keycloak global variable and execute the logout
                Vue.$keycloak.logout();
            }
        },
    };
</script>

<style>
    .page-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        color: white;
        text-align: center;
        background-color: white;
    }
</style>