<template>
  <div class="header-app">
    <b-navbar toggleable="lg">
      <div class="logo">
        <img :src="logoUrl" alt="Frazillio solutions" />
      </div>
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <font-awesome-icon
            v-on:click="logout"
            class="fa-xl"
            icon="fa-solid fa-right-from-bracket"
          />
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      logoUrl: require("@/assets/FF Solutions 01.png"),
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
  },
  watch: {
    // call again the method if the route changes
  },
  methods: {
    logout: () => {
      Vue.$keycloak.logout();
    },
  },
};
</script>

<style>
.fa-right-from-bracket {
  color: white;
  cursor: pointer;
}
.logo {
  display: block;
  margin-left: 45%;
}
</style>